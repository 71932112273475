/** LOCALS **/


import i18n from 'utils/i18n';


/** MAIN **/


let App;
let Root;

i18n.load().then(async() => {
    App = (await import('./App')).default;
    Root = (await import('./Root')).default;

    App.render({
        render: Root,
        target: DOM_CONTAINER,
    });
});

