/** EXTERNALS **/


import { I18n } from 'i18n-js';
import moment from 'moment';


/** LOCALS **/


/** HELPERS **/


const languages = {
    da: 'Dansk',
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    ja: '日本語',
    ko: '한국어',
    nl: 'Nederlands',
    pt: 'Português (Brasil)',
    ru: 'Русский',
    zh: '简体中文',
};

function fromCookie() {
    if (typeof document === 'undefined') return null;
    if (!document.cookie) return null;

    const cookie = document.cookie.split(';')
        .map(cookie => cookie.split('='))
        .find(pair => pair[0].trim() === 'locale');

    if (!cookie) return null;

    return cookie[1].trim();
}


function fromLocalStorage() {
    if (typeof localStorage === 'undefined') return null;

    try {
        const item = localStorage.getItem('_ml_locale');
        const result = JSON.parse(item);

        return result ? result.value : null;
    } catch (e) {
        return null;
    }
}

function fromNavigator() {
    if (typeof navigator === 'undefined') return [];
    return [
        ...(navigator.languages ?? []),
        navigator.language,
        navigator.userLanguage,
        navigator.systemLanguage,
        navigator.browserLanguage,
    ];
}

function detectLocale() {
    return [
        fromCookie(),
        fromLocalStorage(),
        ...fromNavigator(),
    ]
        .filter(value => typeof value === 'string')
        .map(locale => locale.split('-')[0]) // en-US -> en
        .map(locale => locale.toLowerCase())
        .filter(locale => locale in languages)
        .find((_, i) => i === 0);
}

function russianPluralization(count) {
    // one: number ending in 1, excluding ending in 11
    if (count % 10 === 1 && count % 100 !== 11) {
        return 'one';
    }

    // few: numbers ending in 2, 3, 4, excluding 12, 13, 14
    if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
        return 'few';
    }

    // many: all other numbers
    return 'many';
}

function create() {
    const i18n = new I18n(null, {
        defaultLocale: 'en',
        locale: detectLocale() ?? 'en',
    });

    // We also want to update moment's locale.
    // MindMeister gives us only "zh" for Chinese on the SessionOwner
    // but moment differentiates between "cn", "hk", "tw" and has no "zh"
    // so we default to China (Mainland) by converting "zh" to "zh-cn"
    moment.locale(i18n.locale === 'zh' ? 'zh-cn' : i18n.locale);

    // Used by mopinion
    global[CONFIG.mopinion.metaKeys.locale] = i18n.locale;

    // Custom pluralization rules for some languages.
    i18n.pluralization.ru = russianPluralization;
    i18n.pluralization.zh = () => ['other'];
    i18n.pluralization.ko = () => ['other'];
    i18n.pluralization.ja = () => ['other'];

    // Custom loading function.
    i18n.load = async function() {
        const translations = await import(`assets/i18n/${i18n.locale}.yml`);

        i18n.store(translations.default);
    };

    return i18n;
}


/** MAIN **/


export default create();
